<template>
  <div class="ha-dialog" @click.self="handleCloseDialog">
    <div class="dialog-window">
      <div class="icon-container" @click="handleCloseDialog">
        <IconCross class="icon" />
      </div>

      <div class="content">
        <slot>no content.</slot>
      </div>
      <!-- TODO:ローディング表示をVAFに還元するかは別途検討する -->
      <template v-if="isLoading">
        <div class="loading">
          <IconLoading class="icon-loading" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconCross from '@/assets/icons/icon_xmark.svg'
import IconLoading from '@/assets/icons/icon-loading.svg'

type Props = {
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
})

type Emits = {
  (e: 'close'): void
}
const emit = defineEmits<Emits>()

const handleCloseDialog = () => {
  emit('close')
}

const isLoading = computed(() => {
  return props.loading
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ha-dialog {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: v.$zindex-dialog;

  > .dialog-window {
    background-color: v.$white;
    height: fit-content;
    position: relative;
    width: fit-content;

    > .loading {
      background: rgba(255, 255, 255, 0.7);
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 100;
    }

    > .loading > .icon-loading {
      height: 150px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 150px;

      @include m.tb() {
        height: 100px;
        width: 100px;
      }
    }
  }

  > .dialog-window > .content {
    height: fit-content;
    padding: v.space(8);
    position: relative;
    width: fit-content;
  }
}

.icon-container {
  align-items: center;
  background: v.$white;
  border: 1px solid v.$gray;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 40px;
  z-index: 10;

  @include m.sp() {
    height: 32px;
    width: 32px;
  }

  > .icon {
    fill: v.$gray-5;
    height: 16px;
    width: 16px;
  }
}
</style>
